var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.isCancel
    ? _c(
        "li",
        {
          staticClass: "offer-item",
          class: {
            "offer-item-isVisited": _vm.isVisited
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "ui-btn",
              on: {
                click: function() {
                  if (_vm.isRegistered) {
                    return
                  }
                  if (_vm.isPaidOffer && _vm.isClient) {
                    _vm.gotoProfile(_vm.row)
                    return
                  }
                  _vm.gotoOffer()
                }
              }
            },
            [
              _vm.isPaidOffer && _vm.isClient
                ? [
                    _vm.isPartCancel
                      ? _c("div", { staticClass: "offer-badge" }, [
                          _c("div", { staticClass: "badge-partCancel" }, [
                            _vm._v("부분취소")
                          ])
                        ])
                      : _vm._e(),
                    _c("div", { staticClass: "paid-info" }, [
                      _c("div", {
                        staticClass: "artist-img",
                        style: {
                          backgroundImage:
                            "url(" + _vm.$lib.cdnUrl(_vm.row.teamImgUrl) + ")"
                        }
                      }),
                      _c("div", { staticClass: "artist-info" }, [
                        _c("div", { staticClass: "artist-name" }, [
                          _vm._v(_vm._s(_vm.row.teamName))
                        ]),
                        _c("div", { staticClass: "artist-genre" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$lib.t_genre(
                                _vm.row.genre || _vm.row.categoryName
                              )
                            )
                          )
                        ]),
                        _c("div", { staticClass: "artist-payAmt" }, [
                          _vm._v(
                            _vm._s(
                              _vm.isPartCancel
                                ? _vm.$lib.addComma(_vm.row.cancellableAmt)
                                : _vm.$lib.addComma(_vm.row.payAmt)
                            ) + "원"
                          )
                        ])
                      ])
                    ])
                  ]
                : [
                    _c("div", { staticClass: "offer-badge" }, [
                      _vm.isRead
                        ? _c("div", { staticClass: "badge-read" }, [
                            _vm._v("읽음")
                          ])
                        : _vm._e(),
                      !!_vm.row.userReferrer
                        ? _c("div", { staticClass: "badge-hot" }, [_vm._v("H")])
                        : _vm._e(),
                      _vm.isArtist && !_vm.isEnd && _vm.row.newOffer === "y"
                        ? _c("div", { staticClass: "badge-new" }, [_vm._v("N")])
                        : _vm._e(),
                      _vm.isDirect
                        ? _c("div", { staticClass: "badge-1-1" }, [
                            _vm._v("1 : 1")
                          ])
                        : _vm._e(),
                      _vm.isEnd
                        ? _c("div", { staticClass: "badge-end" }, [
                            _vm._v("마감")
                          ])
                        : _vm._e(),
                      _vm.isPartCancel
                        ? _c("div", { staticClass: "badge-partCancel" }, [
                            _vm._v("부분취소")
                          ])
                        : _vm._e(),
                      !_vm.isEnd && _vm.dDay >= 0
                        ? _c("div", { staticClass: "badge-dDay" }, [
                            _vm._v(
                              _vm._s(
                                _vm.dDay > 0 ? "D - " + _vm.dDay : "오늘마감"
                              )
                            )
                          ])
                        : _vm._e(),
                      _c("div", { staticClass: "offer-created" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$lib.timeFormat.created(_vm.row.createdDate)
                            ) +
                            " "
                        )
                      ])
                    ]),
                    _c("div", { staticClass: "offer-title new-offer" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$lib.t_genre(
                              _vm.row.genre || _vm.row.categoryName
                            )
                          )
                      ),
                      _c("span", [_vm._v("|")]),
                      _vm._v(_vm._s(_vm.row.eventType) + " "),
                      _c("div", { staticClass: "new" })
                    ]),
                    _c("div", { staticClass: "offer-info" }, [
                      _c(
                        "div",
                        { staticClass: "info-date" },
                        [
                          _c("icon", {
                            staticClass: "icon",
                            attrs: {
                              src: "img/common/calendar.png",
                              width: "1.6rem",
                              height: "3.7rem",
                              cover: false
                            }
                          }),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm
                                  .moment(_vm.row.playDate * 1000)
                                  .format("YYYY.MM.DD")
                              ) +
                              " "
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "info-addr" },
                        [
                          _c("icon", {
                            staticClass: "icon",
                            attrs: {
                              src: "img/common/map-ico.png",
                              width: "1.4rem",
                              height: "3.8rem",
                              cover: false
                            }
                          }),
                          _vm._v(
                            " " +
                              _vm._s(_vm.$lib.addrMinify(_vm.row.address)) +
                              " "
                          )
                        ],
                        1
                      ),
                      _vm.row.wgender
                        ? _c(
                            "div",
                            { staticClass: "info-addr" },
                            [
                              _c("icon", {
                                staticClass: "icon",
                                attrs: {
                                  src: "img/common/sex-ico.png",
                                  width: "2.1rem",
                                  height: "3.8rem",
                                  cover: false
                                }
                              }),
                              _vm._v(" " + _vm._s(_vm.row.wgender) + " ")
                            ],
                            1
                          )
                        : _vm._e()
                    ]),
                    _c(
                      "div",
                      { staticClass: "offer-money" },
                      [
                        _vm.isPaidOffer
                          ? [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$lib.addComma(_vm.row.payAmt)) +
                                  "원 "
                              )
                            ]
                          : [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$lib.moneyRangeFormat(_vm.row)) +
                                  " "
                              )
                            ]
                      ],
                      2
                    )
                  ]
            ],
            2
          ),
          _vm.isSimple === false
            ? [
                _vm.isClient
                  ? [
                      _vm.isRegistered
                        ? [
                            _c("div", { staticClass: "offer-btn-area" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "offer-btn ui-btn",
                                  on: {
                                    click: function() {
                                      return _vm.goToRegisteredOffer()
                                    }
                                  }
                                },
                                [_vm._v(" 이 의뢰를 선택 > ")]
                              )
                            ])
                          ]
                        : _vm.row.recCount > 0
                        ? [
                            _vm.isPaidOffer
                              ? _c(
                                  "div",
                                  { staticClass: "offer-btn-area multi" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "offer-btn ui-btn",
                                        on: {
                                          click: function() {
                                            return _vm.gotoPayment()
                                          }
                                        }
                                      },
                                      [_vm._v(" 결제 상세 ")]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "offer-btn ui-btn",
                                        on: {
                                          click: function() {
                                            return _vm.gotoWritingReview(
                                              _vm.row
                                            )
                                          }
                                        }
                                      },
                                      [_vm._v(" 리뷰 쓰기 ")]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "offer-btn ui-btn",
                                        on: {
                                          click: function() {
                                            return _vm.createMsgRoom(_vm.row)
                                          }
                                        }
                                      },
                                      [_vm._v(" 채팅하기 ")]
                                    )
                                  ]
                                )
                              : _c(
                                  "div",
                                  { staticClass: "offer-btn-area" },
                                  [
                                    _vm.isDirect
                                      ? [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "artist-profile",
                                              on: {
                                                click: function($event) {
                                                  return _vm.$router.push(
                                                    "/artist/profile/" +
                                                      _vm.row.artistidx
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c("div", {
                                                staticClass: "artist-img",
                                                style: {
                                                  backgroundImage:
                                                    "url(" +
                                                    _vm.$lib.cdnUrl(
                                                      _vm.row.teamImgUrl
                                                    ) +
                                                    ")"
                                                }
                                              }),
                                              _c(
                                                "div",
                                                { staticClass: "artist-name" },
                                                [
                                                  _vm._v(
                                                    _vm._s(_vm.row.teamName)
                                                  )
                                                ]
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "offer-btn ui-btn",
                                              on: {
                                                click: function() {
                                                  if (
                                                    _vm.$lib.execNgWordProcess(
                                                      _vm.$store
                                                    )
                                                  ) {
                                                    return
                                                  }
                                                  _vm.$router.push(
                                                    "/client/my-offer/" +
                                                      _vm.row.offerUid +
                                                      "/" +
                                                      _vm.row.artistidx
                                                  )
                                                }
                                              }
                                            },
                                            [_vm._v(" 견적 보기 ")]
                                          )
                                        ]
                                      : [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "offer-btn ui-btn",
                                              on: {
                                                click: function() {
                                                  if (
                                                    _vm.$lib.execNgWordProcess(
                                                      _vm.$store
                                                    )
                                                  ) {
                                                    return
                                                  }
                                                  _vm.$router.push(
                                                    "/client/my-offer/" +
                                                      _vm.row.offerUid +
                                                      "/recruiter"
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$lib.addComma(
                                                      _vm.row.recCount
                                                    )
                                                  ) +
                                                  "명의 견적 보기 "
                                              )
                                            ]
                                          )
                                        ]
                                  ],
                                  2
                                )
                          ]
                        : _vm._e()
                    ]
                  : _vm._e(),
                _vm.isArtist && _vm.row.proPrice > 0
                  ? [
                      _vm.isPaidOffer
                        ? _c("div", { staticClass: "offer-btn-area multi" }, [
                            _c(
                              "div",
                              {
                                staticClass: "offer-btn ui-btn",
                                on: {
                                  click: function() {
                                    return _vm.gotoPayment()
                                  }
                                }
                              },
                              [_vm._v(" 결제 상세 ")]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "offer-btn ui-btn",
                                on: {
                                  click: function() {
                                    return _vm.gotoProfile(_vm.row)
                                  }
                                }
                              },
                              [_vm._v(" 지원서 보기 ")]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "offer-btn ui-btn",
                                on: {
                                  click: function() {
                                    return _vm.createMsgRoom(_vm.row)
                                  }
                                }
                              },
                              [_vm._v(" 채팅하기 ")]
                            )
                          ])
                        : _c("div", { staticClass: "offer-btn-area" }, [
                            _c(
                              "div",
                              {
                                staticClass: "offer-btn ui-btn",
                                on: {
                                  click: function() {
                                    return _vm.gotoProfile(_vm.row)
                                  }
                                }
                              },
                              [_vm._v(" 지원서 보기 ")]
                            )
                          ])
                    ]
                  : _vm._e()
              ]
            : _vm._e()
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }