<template>
  <li
    v-if="!isCancel"
    class="offer-item"
    :class="{
      'offer-item-isVisited': isVisited,
    }"
    >
    <div
      class="ui-btn"
      @click="() => {
        if (isRegistered) {
          return
        }
        if (isPaidOffer && isClient) {
          gotoProfile(row)
          return
        }
        gotoOffer()
      }"
    >
      <template v-if="isPaidOffer && isClient">
        <div class="offer-badge" v-if="isPartCancel">
          <div class="badge-partCancel">부분취소</div>
        </div>
        <div class="paid-info">
          <div
            class="artist-img"
            :style="{
              backgroundImage: `url(${$lib.cdnUrl(row.teamImgUrl)})`,
            }"
          />
          <div class="artist-info">
            <div class="artist-name">{{row.teamName}}</div>
            <div class="artist-genre">{{$lib.t_genre(row.genre || row.categoryName)}}</div>
            <div class="artist-payAmt">{{isPartCancel ? $lib.addComma(row.cancellableAmt) : $lib.addComma(row.payAmt)}}원</div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="offer-badge">
          <div class="badge-read" v-if="isRead">읽음</div>
          <div class="badge-hot" v-if="!!row.userReferrer">H</div>
          <div class="badge-new" v-if="isArtist && !isEnd && row.newOffer === 'y'">N</div>
          <div class="badge-1-1" v-if="isDirect">1 : 1</div>
          <div class="badge-end" v-if="isEnd">마감</div>
          <div class="badge-partCancel" v-if="isPartCancel">부분취소</div>
          <div class="badge-dDay" v-if="!isEnd && dDay >= 0">{{dDay > 0 ? `D - ${dDay}` : '오늘마감'}}</div>

          <div class="offer-created">
            {{ $lib.timeFormat.created(row.createdDate) }}
          </div>
        </div>

        <!-- as-is -->
        <!-- <div class="offer-title">
          {{$lib.t_genre(row.genre || row.categoryName)}}<span>|</span>{{row.eventType}}
        </div> -->
        <!-- //as-is -->

        <!-- 새로운 제안 요청 시 -->
        <div class="offer-title new-offer">
          {{$lib.t_genre(row.genre || row.categoryName)}}<span>|</span>{{row.eventType}}
          <div class="new"></div>
        </div>
        <!-- // 새로운 제안 요청 시 -->

        <div class="offer-info">
          <div class="info-date">
            <icon class="icon" src="img/common/calendar.png" width="1.6rem" height="3.7rem" :cover="false" />
            {{moment(row.playDate * 1000).format('YYYY.MM.DD')}}
          </div>
          <div class="info-addr">
            <icon class="icon" src="img/common/map-ico.png" width="1.4rem" height="3.8rem" :cover="false" />
            {{$lib.addrMinify(row.address)}}
          </div>
          <div class="info-addr" v-if="row.wgender">
            <icon class="icon" src="img/common/sex-ico.png" width="2.1rem" height="3.8rem" :cover="false" />
            {{row.wgender}}
          </div>
        </div>

        <div class="offer-money">
          <template v-if="isPaidOffer">
            {{$lib.addComma(row.payAmt)}}원
          </template>
          <template v-else>
            {{ $lib.moneyRangeFormat(row) }}
          </template>
        </div>
      </template>
    </div>

    <template v-if="isSimple === false">

      <!-- 일반회원일 때 -->
      <template v-if="isClient">
        <template v-if="isRegistered">
          <div class="offer-btn-area">
            <div
              class="offer-btn ui-btn"
              @click="() => goToRegisteredOffer()"
            >
              이 의뢰를 선택 >
            </div>
          </div>
        </template>
        <template v-else-if="row.recCount > 0">
          <div v-if="isPaidOffer" class="offer-btn-area multi">
            <div
              class="offer-btn ui-btn"
              @click="() => gotoPayment()"
            >
              결제 상세
            </div>
            <div
              class="offer-btn ui-btn"
              @click="() => gotoWritingReview(row)"
            >
              리뷰 쓰기
            </div>
            <div
              class="offer-btn ui-btn"
              @click="() => createMsgRoom(row)"
            >
              채팅하기
            </div>
          </div>
          <div v-else class="offer-btn-area">
            <template v-if="isDirect">
              <div class="artist-profile" @click="$router.push(`/artist/profile/${row.artistidx}`)">
                <div
                  class="artist-img"
                  :style="{
                    backgroundImage: `url(${$lib.cdnUrl(row.teamImgUrl)})`,
                  }"
                />
                <div class="artist-name">{{row.teamName}}</div>
              </div>
              <div
                class="offer-btn ui-btn"
                @click="() => {
                  if ($lib.execNgWordProcess($store)) return
                  $router.push(`/client/my-offer/${row.offerUid}/${row.artistidx}`)
                }"
              >
              견적 보기
              </div>
            </template>
            <template v-else>
              <div
                class="offer-btn ui-btn"
                @click="() => {
                  if ($lib.execNgWordProcess($store)) return
                  $router.push(`/client/my-offer/${row.offerUid}/recruiter`)
                }"
              >
                {{$lib.addComma(row.recCount)}}명의 견적 보기
              </div>
            </template>
          </div>
        </template>
      </template>

      <!-- 전문가일 때 -->
      <template v-if="isArtist && row.proPrice > 0">

        <div v-if="isPaidOffer" class="offer-btn-area multi">
          <div
            class="offer-btn ui-btn"
            @click="() => gotoPayment()"
          >
            결제 상세
          </div>
          <div
            class="offer-btn ui-btn"
            @click="() => gotoProfile(row)"
          >
            지원서 보기
          </div>
          <div
            class="offer-btn ui-btn"
            @click="() => createMsgRoom(row)"
          >
            채팅하기
          </div>
        </div>
        <div v-else class="offer-btn-area">
          <div
            class="offer-btn ui-btn"
            @click="() => gotoProfile(row)"
          >
            지원서 보기
          </div>
        </div>
      </template>

    </template>

  </li>
</template>

<script>
import Icon from '@/components/common/Icon'

export default {
  components: {
    Icon,
  },
  props: {
    row: {
      type: Object,
      required: true,
    },
    isSimple: {
      type: Boolean,
      default: false,
    },
    noPaidOffer: {
      type: Boolean,
      default: false,
    },
    registered: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      artistData: {},
      // 로컬용 읽음여부
      isVisited: false,
      events: {
        root: [],
      },
    }
  },
  computed: {
    localStorageKey() {
      return `read:offer:${this.row.offerUid}`
    },
    isRead: {
      get() {
        if (!this.isArtist) return false
        return this.row.proClientReadDate > 0
      },
    },
    isEnd: {
      get() {
        const now = Math.floor(Date.now() / 1000)
        const end = Number(this.row.playDate || 0)

        if (now >= end || ['ing', 'wait'].indexOf(this.row.nowState) === -1) return true

        return false
      },
    },
    isPartCancel: {
      get() {
        if (['partCancel'].indexOf(this.row.nowState) !== -1) return true

        return false
      },
    },
    dDay: {
      get() {
        const now = moment(moment(new Date()).format('YYYY-MM-DD'))
        const end = moment(moment(Number(this.row.playDate) * 1000).format('YYYY-MM-DD'))
        return end.diff(now, 'days')
      },
    },
    offerData() {
      return this.row
    },
    userData: {
      get() {
        return this.$store.state.user.userData
      },
    },
    isClient: {
      get() {
        return this.userData.category === 'client'
      },
    },
    isArtist: {
      get() {
        return this.userData.category === 'artist'
      },
    },
    isCancel() {
      if (this.isDirect) {
        return !this.row.artistidx || this.row.artistidx == 0
      }
      return false
    },
    offerState() {
      return state => {
        if (!state) return state
        switch (state) {
          case 'ing': return '모집중'
          case 'wait': return '입금대기'
          case 'end': return '결제 완료'
          case 'cancelRequest': return '결제취소요청'
          case 'cancel': return '결제 취소'
          case 'partCancel': return '부분 취소'
          case 'payRequest': return '지급요청'
          case 'pay': return '지급 완료'
          case 'selfEnd': return '직접마감'
        }
      }
    },
    isRegistered() {
      return !!this.registered
    },
    isPaidOffer() {
      if (this.isRegistered) {
        return false
      }

      if (this.noPaidOffer) {
        return false
      }

      const condition = {
        default: [
          () => ['ing', 'selfEnd', 'autoEnd', 'cancel'].indexOf(this.offerData?.nowState) === -1,
          () => this.offerData?.payStatus === '00',
        ],
        artist: [
          () => this.userData?.artistidx == this.offerData?.artistidx,
        ],
        client: [
          () => this.userData?.useridx == this.offerData?.useridx,
          // () => this.artistidx === this.offerData?.artistidx,
        ],
      }
      if (condition.default.every(fn => fn())) {
        return condition[this.isArtist ? 'artist' : 'client'].every(fn => fn())
      }

      return false
    },
    isDirect() {
      return this.row?.mode === 'direct'
    }
  },
  mounted() {
    this.events.root[0] = [this.localStorageKey, () => {
      this.checkVisit()
    }]

    this.events.root.forEach(row => {
      this.$root.$on(...row)
    })

    this.checkVisit()
  },
  beforeDestroy() {
    this.events.root.forEach(row => {
      this.$root.$off(...row)
    })
  },
  methods: {
    gotoProfile(row) {
      sessionStorage.setItem('offerData', JSON.stringify(row))
      if(row.artistidx!=0){
        this.$router.push(`/${this.userData.category}/my-offer/${row.offerUid}/${row.artistidx}`)
      }
    },
    gotoOffer() {
      if (
        this.isEnd &&
        this.row.useridx !== this.userData.useridx &&
        this.$route.name !== 'ArtistMyOffer' &&
        !this.isDirect
      ) {
        this.$toast('모집 마감된 의뢰입니다.')
        return
      }

      this.$router.push(`/offer/${this.row.offerUid}`)
    },
    gotoPayment() {
      sessionStorage.setItem(`artistData:${this.row.artistidx}:${this.row.offerUid}`, JSON.stringify(this.row))
      this.$router.push(`/payment/${this.row.offerUid}/${this.row.artistidx}/result`)
    },
    checkVisit() {
      this.isVisited = this.isArtist && localStorage.getItem(this.localStorageKey) > 0
    },
    createMsgRoom({
      offerUid,
      useridx,
      artistidx,
      teamName,
      // platform, // web_PC | web_M
      // userPlatform,
    }) {
      if (!offerUid || !useridx || !artistidx) {
        this.$toast('데이터가 잘못된 의뢰입니다.')
        return
      }

      // if ([platform, userPlatform].some(val => /^web_(PC|M)$/.test(val))) {
      //   this.$store.commit('setModal', {
      //     show: true,
      //     body: `
      //       <span style="font-size: 24px;font-weight: 600;color: #000;">채팅을 사용하지 않는<br>회원입니다.</span>

      //       헤이비글 담당자에게
      //       카카오톡 연결을 요청하세요.`,
      //     textAlign: 'text-center',
      //     confirm: '확인',
      //     confirmCb: modal => {
      //       modal.hide()
      //     },
      //     noBorder: true,
      //     useCancel: false,
      //   })
      //   return
      // }

      const req = {
        method: 'post',
        url: `/chat/${offerUid}`,
        data: {
          useridx,
          targetUseridx: artistidx,
          targetTeamName: teamName,
        },
      }

      this.$http(req)
        .then(({ data }) => {
          log(...Object.values(req), data)

          const resultData = this.$lib.resultCheck(data.resultData)

          if (resultData.success) {
            this.$router.push(`/message/${offerUid}/${artistidx}`)
          } else {
            this.$alert(resultData)
          }
        })
        .catch(log.error)
    },
    gotoWritingReview({ artistidx, useridx }) {
      if (!artistidx || !useridx) {
        return
      }
      this.getArtistProfile(artistidx).then(response => {
        if (response) {
          this.$router.push(`/artist/profile/${artistidx}/write-review`)
        }
      })
    },
    getArtistProfile(artistidx) {
      if (!artistidx) return

      const req = {
        method: 'get',
        url: `/artist/profile/${artistidx}`,
      }

      return this.$http(req)
        .then(({ data }) => {
          log(...Object.values(req), data)

          const resultData = this.$lib.resultCheck(data.resultData)

          if (resultData.success) {
            const artistData = data.response?.artistData || {}
            const myReviewData = data.response?.myReviewData || null
            const reviewItemData = data.response?.reviewItemData || null

            if (!artistData.artistidx) {
              this.$toast('존재하지 않는 전문가입니다.')
              return null
            }

            sessionStorage.setItem('artistData', JSON.stringify(artistData))
            sessionStorage.setItem('reviewItemData', JSON.stringify(reviewItemData))
            sessionStorage.setItem('myReviewData', JSON.stringify(myReviewData))
          } else {
            this.$alert(resultData)
            return null
          }

          return data?.response
        })
        .catch(() => {
          return null
        })
    },
    goToRegisteredOffer(isConfirm = false) {
      const registeredGenreSet = new Set(this.$lib.t_genre_reverse(this.registered).split(/,\s?/))
      const selectedGenreSet = new Set(this.$lib.t_genre_reverse(this.row.genre).split(/,\s?/))

      if (
        !isConfirm &&
        Array.from(selectedGenreSet).every((genre) => !registeredGenreSet.has(genre))
      ) {
        this.$store.commit('setModal', {
          show: true,
          body: `
            <span style="font-size: 24px;font-weight: 600;color: #000;">전문가의 분야와<br>섭외하려는 분야가<br>다릅니다.</span>

            <span style="color: #959595;">그래도 견적을 요청하시겠어요?</span>`,
          textAlign: 'text-center',
          cancel: '취소',
          confirm: '네 요청합니다.',
          confirmCb: modal => {
            modal.hide()
            this.goToRegisteredOffer(true)
          },
          noBorder: true,
          useCancel: true,
        })
        return
      }

      this.$router.push({
        name: 'ClientRegisteredOfferDetail',
        params: {
          offerUid: this.row.offerUid,
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/constant.scss';

.offer-item {
  margin-top: 3rem;
  .paid-info {
    display: flex;
    margin-bottom: 2.8rem;
    .artist-img {
      width: 150px;
      height: 115px;
      border-radius: 7px;
      background-size: cover;
      background-position: center;
      margin-right: 30px;
    }
    .artist-info {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .artist-name {
        font-weight: 600;
        font-size: 2.2rem;
        color: #000;
        margin-bottom: 6px;
      }
      .artist-genre {
        font-size: 1.9rem;
        font-weight: 400;
        color: #999;
        margin-bottom: 10px;
      }
      .artist-payAmt {
        font-size: 2rem;
        font-weight: 500;
        color: #a059b6;
      }
    }
  }

  .offer-badge {
    overflow: hidden;
    > div:not(.offer-created) {
      float: left;
      margin-right: 0.6rem;
      margin-bottom: 1.3rem;
      width: auto;
      line-height: 2.3rem;
      height: 2.1rem;
      border-radius: 0.3rem;
      font-size: 1.1rem;
      padding: 0 0.6rem;
      color: #fff;
      background-color: #979797;
      text-align: center;

      &.badge-end {
        background-color: #9a9a9a;
      }
      &.badge-1-1 {
        background-color: #cacaca;
      }
      &.badge-read {
        background-color: #cacaca;
      }
      &.badge-new {
        background-color: #ff8f9f;
        // background-color: #AB54BB;
      }
      &.badge-dDay {
        background-color: #2bc4cc;
      }

      &.artistHot {
        background-color: $color-deepLavender;
      }

      &.artistCheck {
        background-color: #30c39e;
      }

      &.badge-end {
        background-color: #9a9a9a;
      }
      &.badge-1-1 {
        background-color: #cacaca;
      }
      &.badge-read {
        background-color: #cacaca;
      }
      &.badge-dDay {
        background-color: #2bc4cc;
      }
      &.badge-new {
        background-color: #ff8f9f;
        // background-color: #AB54BB;
      }
      &.badge-hot {
        background-color: $color-deepLavender;
      }
    }

    .offer-created {
      float: right;
      color: #999;
      font-size: 16px;
    }
  }
  .offer-title {
    display: inline-block;
    position: relative;
    color: #000;
    font-size: 2.2rem;
    line-height: 2.6rem;
    font-weight: 500;
    span {
      color: #999;
      margin: 0 1rem;
      font-size: 1.6rem;
      position: relative;
      font-weight: 300;
      top: -0.4rem;
    }
    &.new-offer {
      font-weight: 600;
      .new {
        position: absolute;
        top:0;
        right: -12px;
        width: 6px;
        height: 6px;
        background-color: #F33841;
        border-radius: 3px;
      }
    }
  }

  .offer-info {
    line-height: 4rem;
    color: #999;
    overflow: hidden;
    font-size: 1.9rem;
    margin-bottom: 0.5rem;

    .info-date,
    .info-addr,
    .info-gender {
      float: left;
    }

    .info-date,
    .info-addr {
      margin-right: 2.5rem;
    }

    .icon {
      display: block;
      margin-right: 0.5rem;
      float: left;
    }

    .info-date {
      .icon {
        margin-top: 0rem;
      }
    }

    .info-addr {
      .icon {
        margin-top: 0rem;
      }
    }
  }

  .offer-money {
    margin-bottom: 2rem;
    height: 2.55rem;
    font-size: 2rem;
    font-weight: 600;
    color: #a059b6;
  }

  .offer-border-line {
    background-color: #ececec;
    margin-bottom: 2rem;
    height: 1px;
    width: 40%;
  }

  .offer-btn-area {
    display: flex;
    justify-content: space-between;
    .offer-btn {
      margin: 0 0 3rem;
      border: 0px solid #b3b3b3;
      border-radius: 0.7rem;
      text-align: center;
      background-color: #f4f4f4;
      color: #555;
      font-size: 1.8rem;
      line-height: 6.2rem;
      height: 5.7rem;
      font-weight: 500;
      width: 100%;
    }
    .artist-profile {
      cursor: pointer;
      display: flex;
      align-items: center;
      height: 50px;
      overflow: hidden;
      .artist-img {
        border-radius: 50%;
        width: 40px;
        height: 40px;
        background-size: cover;
        background-position: center;
        margin-right: 12px;
      }
      .artist-name {
        flex: 1;
        font-size: 18px;
        color: #000;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        margin-right: 12px;
      }
    }
    .artist-profile ~ .offer-btn {
      min-width: 150px;
      width: 150px;
    }
    &.multi {
      .offer-btn {
        margin-right: 18px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
  &.offer-item-isVisited {
    .offer-title {
      color: #909090
    }
  }
}
</style>
